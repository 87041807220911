import { sendAuthenticatedRequest } from '../auth.js';
import { LOG_LEVELS, logMessage } from '../logging.js';

class ImageModel {
  constructor() {
    this.localUrl = null;
  }

  setHeaders(headers) {
    this.headers = headers;
  }

  async fetchImage(url) {
    // Fetch the image securely using the provided headers
    try {
      const response = await sendAuthenticatedRequest(url, {
        method: 'GET',
        headers: this.headers,
        responseType: 'blob', // Expect a blob response
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      this.localUrl = URL.createObjectURL(response.blob);
      return this.localUrl;
    } catch (error) {
      logMessage(LOG_LEVELS.ERROR, 'Error fetching image:', error.toString());
      throw error; // Re-throw to let the controller handle it
    }
  }

  getLocalUrl() {
    return this.localUrl;
  }

  updateLocalUrl(url) {
    this.localUrl = url;
  }
}

export { ImageModel };
