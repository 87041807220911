import {
  deleteCard,
  initiateCornerDownload,
} from '../../data/update_operations.js';
import stateManager from '../../data/state_manager.js';
import { LOG_LEVELS, logMessage } from '../../logging.js';
import { EditFormPopupController } from './edit_form_popup_controller.js';
import { ListingFormPopupController } from './listing_form_popup_controller.js';
import { RedditFormPopupController } from './reddit_form_popup_controller';
import { ImageCarouselModel } from '../../ui_models/image_carousel_model';
import { MenuModel } from '../../ui_models/menu_model';
import { MenuView } from '../../ui_components/menu_view.js';
import { ImageCarouselPopup } from '../../ui_components/popups/image_carousel_popup.js';
import {
  MenuController,
  CompsPopupController,
} from './popup_menu_controllers.js';
import { fetchStreamingData } from '../../data/streaming.js';
import config from '../../../config/index.js';
import { sendAuthenticatedRequest } from '../../auth.js';
import { hideOverlay, showOverlay } from '../../ui_components/overlay.js';
import { fetchCardData } from '../../data/update_operations.js';

class CardDisplayPopupController {
  constructor(tour, card_info, is_public) {
    this.showCardDisplayPopup = this.showCardDisplayPopup.bind(this);
    this.updateArrows = this.updateArrows.bind(this);

    this.model = new ImageCarouselModel(is_public);
    this.view = new ImageCarouselPopup('carousel');
    this.card_info = card_info;
    this.is_public = is_public;
    this.tour = tour;
  }

  async showCardDisplayPopup(matched_items, priced_items, headers) {
    if (this.is_public) {
      this.model.addImage(this.card_info.user_card.front_image_url_large);
      this.model.addImage(this.card_info.user_card.back_image_url_large);

      this.initialize();
      this.configureArrowDisplay(this.view);
      this.addDisplayPopupEventListeners();
      this.view.addDots(this.model.numImages());
      this.updateArrows();

      logMessage(LOG_LEVELS.INFO, 'Card display popup shown');
    } else {
      this.model.setHeaders(headers);
      logMessage(LOG_LEVELS.DEBUG, 'Showing card display popup');

      this.model.addImage(this.card_info.user_card.front_image_url_large);
      this.model.addImage(this.card_info.user_card.back_image_url_large);

      // Initialize menu MVC
      const cardMenuItems = [
        {
          text: 'Edit Card Info',
          action: () => this.editCardEvent(this.card_info, headers),
        },
        {
          text: 'Delete Card',
          action: () => this.deleteCardEvent(this.card_info, headers),
        },
      ];

      const downloadMenuItmes = [
        {
          text: 'Download corners',
          action: () => this.downloadCornersEvent(this.card_info, headers),
        },
      ];

      const sellMenuItems = [
        {
          text: 'View Comps',
          action: () =>
            this.viewCompsEvent(
              this.card_info,
              matched_items,
              priced_items,
              headers
            ),
        },
        {
          text: 'Post on Reddit',
          action: () => this.postOnRedditEvent(this.card_info, headers),
        },
        {
          text: 'List on eBay',
          action: () => this.listCardEvent(this.card_info, headers),
        },
      ];

      const menuModel = new MenuModel(cardMenuItems);
      const downloadMenuModel = new MenuModel(downloadMenuItmes);
      const sellMenuModel = new MenuModel(sellMenuItems);

      this.menuView = new MenuView();

      this.view.appendMenu(this.menuView.instance());

      this.menuController = new MenuController(
        this.tour,
        menuModel,
        downloadMenuModel,
        sellMenuModel,
        this.menuView
      );

      this.initialize();
      this.configureArrowDisplay(this.view);
      this.addDisplayPopupEventListeners();
      this.view.addDots(this.model.numImages());
      this.updateArrows();

      logMessage(LOG_LEVELS.INFO, 'Card display popup shown');
    }
  }

  configureArrowDisplay(popupManager) {
    const { isMobileDevice } = stateManager.getState();

    if (isMobileDevice) {
      popupManager.hideArrows();
    } else {
      popupManager.hideDots();
    }
  }

  addDisplayPopupEventListeners() {
    this.setupCloseButton();
    this.setupCarouselGesture();
    this.setupNavigationArrows();
    logMessage('INFO', 'Event listeners added to popup');
  }

  setupCloseButton() {
    this.view.bindCloseButton(() => this.closePopup());
  }

  setupCarouselGesture() {
    const carousel = document.getElementById('imageCarousel');
    let touchstartX = 0,
      touchendX = 0;

    if (carousel) {
      carousel.addEventListener(
        'touchstart',
        (e) => {
          touchstartX = e.changedTouches[0].screenX;
        },
        { passive: true }
      );

      carousel.addEventListener(
        'touchend',
        (e) => {
          touchendX = e.changedTouches[0].screenX;
          this.handleGesture(touchstartX, touchendX);
        },
        { passive: true }
      );
    }
  }

  setupNavigationArrows() {
    this.view.bindNextButton(() => this.showNextImage());
    this.view.bindPrevButton(() => this.showPrevImage());
  }

  closePopup() {
    this.view.remove();
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1); // Restore scroll position
    stateManager.updateState({ popupOnDisplay: false, reinitialize: true });
  }

  async initialize() {
    try {
      // document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`; // Remember scroll position
      const imageUrl = await this.model.getImage(0);
      this.view.updateImage(imageUrl);
      this.view.setActiveDot(0);
    } catch (error) {
      console.error('Error initializing:', error);
      return false;
    }
  }

  viewCompsEvent(card_info, matched_items, priced_items, headers) {
    this.menuController.toggleMenu();
    let compsController = new CompsPopupController();
    compsController.showCompsPopup(
      this.view,
      card_info,
      matched_items,
      priced_items,
      headers
    );
  }

  editCardEvent(card_info, headers) {
    this.menuController.toggleMenu();
    let editController = new EditFormPopupController(card_info, headers);
    editController.showEditCardPopup(this);
  }

  async downloadCornersEvent(card_info, headers) {
    const front_image_url_parent =
      this.card_info.user_card.front_image_url_parent;
    const back_image_url_parent =
      this.card_info.user_card.back_image_url_parent;
    const front_coordinates = this.card_info.user_card.front_coordinates
      ? JSON.parse(this.card_info.user_card.front_coordinates)
      : null;
    const back_coordinates = this.card_info.user_card.back_coordinates
      ? JSON.parse(this.card_info.user_card.back_coordinates)
      : null;

    if (
      !front_image_url_parent ||
      !front_coordinates ||
      !back_image_url_parent ||
      !back_coordinates
    ) {
      alert(
        'This card was scanned in an early release. We do not have a stored record of the corners. Please edit the card using the menu on the right and rescan the necessary images.'
      );
    } else {
      showOverlay();
      this.menuController.toggleMenu();

      const payload = {
        user_card_id: card_info.user_card.user_card_id,
      };

      const response = await initiateCornerDownload(
        headers,
        payload,
        this.handleNoSubscription
      );
      const confirmResponse = await this.handleDownloadConflict(
        response,
        headers,
        payload
      );

      if (confirmResponse.userDecision && confirmResponse.response.ok) {
        const requestId = confirmResponse.response.body.request_id;
        const controller = new AbortController();

        fetchStreamingData(
          `${config.detectionUrl}/get-data-stream/${requestId}`,
          headers,
          (data) => this.handleDownloadReady(data),
          controller.signal
        );
      }
    }
  }

  async isEbayAccountConnected(headers) {
    try {
      const response = await fetchCardData(
        `${config.detectionUrl}/check-ebay-connection`,
        'GET',
        headers,
        null
      );

      return response.body.connected;
    } catch (error) {
      console.error('Error checking eBay connection:', error);
      return false;
    }
  }

  async isRedditAccountConnected(headers) {
    try {
      const response = await fetchCardData(
        `${config.detectionUrl}/check-reddit-connection`,
        'GET',
        headers,
        null
      );

      return response.body.connected;
    } catch (error) {
      console.error('Error checking reddit connection:', error);
      return false;
    }
  }

  async listCardEvent(card_info, headers) {
    this.menuController.toggleMenu();

    if (await this.isEbayAccountConnected(headers)) {
      let listContrller = new ListingFormPopupController(card_info, headers);
      listContrller.showListingPopup(this);
    } else {
      alert(
        'Please connect your eBay account under the Integrations menu option.'
      );
    }
  }

  async postOnRedditEvent(card_info, headers) {
    this.menuController.toggleMenu();

    if (await this.isRedditAccountConnected(headers)) {
      let listContrller = new RedditFormPopupController(card_info, headers);
      listContrller.showListingPopup(this);
    } else {
      alert(
        'Please connect your reddit account under the Integrations menu option.'
      );
    }
  }

  async handleDownloadConflict(response, headers, payload) {
    if (response.status === 409) {
      const userDecision = confirm(
        'Download 5 sets of corner images for free. Continue?'
      );
      if (userDecision) {
        payload.user_decision = true;
        const res = await initiateCornerDownload(
          headers,
          payload,
          this.handleNoSubscription,
          true
        );
        return { response: res, userDecision: true };
      } else {
        hideOverlay();
        return { response, userDecision: false };
      }
    }
    return { response, userDecision: true };
  }

  async handleDownloadReady(data, headers) {
    console.log(encodeURIComponent(data.download_id));

    const response = await sendAuthenticatedRequest(
      `${config.detectionUrl}/download-file?zip-id=${data.download_id}`,
      {
        method: 'GET',
        headers: headers,
        responseType: 'blob', // Expect a blob response
      }
    );

    //Create a URL for the blob and trigger a download
    const blob = response.blob;
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    // Extract filename from Content-Disposition header
    const contentDisposition = response.headers.get('Content-Disposition');
    let filename = 'download.zip';
    if (contentDisposition) {
      const match = contentDisposition.match(/filename="(.+)"/);
      if (match) {
        filename = match[1];
      }
    }
    a.download = filename;
    document.body.appendChild(a); // Append the anchor to the body
    a.click(); // Trigger the download
    document.body.removeChild(a); // Remove the anchor from the body
    hideOverlay();
  }

  handleNoSubscription() {
    hideOverlay();
    document.getElementById('upgradeModal').style.display = 'block';
    // alert('You have exceeded the allowed number of downloads.')
  }

  async deleteCardEvent(card_info, headers) {
    if (await deleteCard(card_info, headers)) {
      this.menuController.toggleMenu();
      this.view.remove();
      stateManager.updateState({ popupOnDisplay: false, reinitialize: true });
    }
  }

  async handleGesture(touchstartX, touchendX) {
    const swipeThreshold = 30; // pixels needed to consider the action a swipe
    const distance = touchendX - touchstartX;

    if (distance < -swipeThreshold) {
      await this.showNextImage();
      this.view.setActiveDot(this.model.getCurrentIndex());
    } else if (distance > swipeThreshold) {
      await this.showPrevImage();
      this.view.setActiveDot(this.model.getCurrentIndex());
    }
  }

  updateArrows() {
    const { isMobileDevice } = stateManager.getState();

    if (isMobileDevice) {
      return;
    }

    // Disable left arrow if on the first image
    if (this.model.isIndexAtStart()) {
      this.view.disableLeftArrow();
      this.view.enableRightArrow();
    } else if (this.model.isIndexAtEnd()) {
      this.view.disableRightArrow();
      this.view.enableLeftArrow();
    }
  }

  async showNextImage() {
    try {
      const imageUrl = await this.model.getNextImage();
      this.view.updateImage(imageUrl);
      this.updateArrows();
    } catch (error) {
      console.error('Error showing next image:', error);
      return false;
    }
  }

  async showPrevImage() {
    try {
      const imageUrl = await this.model.getPrevImage();
      this.view.updateImage(imageUrl);
      this.updateArrows();
    } catch (error) {
      console.error('Error showing previous image:', error);
      return false;
    }
  }

  async updateFrontImage(newLocalUrl, new_card_info) {
    this.card_info = new_card_info;
    this.model.updateLocalImageAtIndex(0, newLocalUrl);

    if (this.model.getCurrentIndex() == 0) {
      this.view.updateImage(newLocalUrl);
    }
  }

  async updateBackImage(newLocalUrl, new_card_info) {
    this.card_info = new_card_info;
    this.model.updateLocalImageAtIndex(1, newLocalUrl);

    if (this.model.getCurrentIndex() == 1) {
      this.view.updateImage(newLocalUrl);
    }
  }
}

export { CardDisplayPopupController };
