class MenuView {
  constructor() {
    this.container = this.createMenuContainer();
    this.menuDots = this.createElement('span', {
      className: 'menu-dots',
      innerHTML: '&#x22EF;',
    });
    this.menuDownload = this.createElement('span', {
      className: 'menu-download',
      innerHTML: '<i class="fas fa-download"></i>',
    });
    this.menuSell = this.createElement('span', {
      className: 'menu-download',
      innerHTML: '<i class="fas fa-dollar-sign"></i>',
    });
    this.dropdownContent = this.createElement('div', {
      className: 'dropdown-content',
    });
    this.container.appendChild(this.menuSell);
    this.container.appendChild(this.menuDownload);
    this.container.appendChild(this.menuDots);
    this.container.appendChild(this.dropdownContent);
  }

  instance() {
    return this.container;
  }

  createMenuContainer() {
    const container = document.createElement('div');
    container.className = 'popup-menu';
    container.id = 'popup_menu_container';
    document.body.appendChild(container);
    return container;
  }

  createElement(tagName, attributes) {
    const element = document.createElement(tagName);
    for (let key in attributes) {
      element[key] = attributes[key];
    }
    return element;
  }

  updateMenuItems(menuItems) {
    // Clear current menu
    this.dropdownContent.innerHTML = '';

    // Create new items
    menuItems.forEach((item) => {
      const menuItem = this.createElement('div', { innerHTML: item.text });
      menuItem.onclick = item.action;
      this.dropdownContent.appendChild(menuItem);
    });
  }

  bindMenu(handler) {
    this.menuDots.onclick = handler;
  }

  bindDownload(handler) {
    this.menuDownload.onclick = handler;
  }

  bindSell(handler) {
    this.menuSell.onclick = handler;
  }

  toggleDropdown() {
    const currentDisplay = this.dropdownContent.style.display === 'block';
    this.dropdownContent.style.display = currentDisplay ? 'none' : 'block';
  }
}

export { MenuView };
