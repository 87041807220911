const valueMappings = {
  // add your other mappings here if there are any
  player_worn: 'Player Worn',
  game_worn: 'Game Worn',
  not_specific: 'No specific game',
  None: 'None',
  mint: 'Mint/Near Mint',
  excellent: 'Excellent',
  very_good: 'Very Good',
  poor: 'Poor',
  not_sepcified: 'Not specified',
};

export const center_fields = [
  createField('Sport', 'select', {
    attachOnChange: true,
    required: true,
    options: ['Pick Sport', 'Football', 'Baseball', 'Basketball'],
  }),
];

export const center_detected_fields = [
  createField('Sport', 'select', {
    attachOnChange: true,
    required: true,
    options: ['Pick Sport', 'Football', 'Baseball', 'Basketball'],
  }),
];

export const left_detected_fields = [
  createField('Card Identifier', 'text', {
    attachOnChange: true,
    required: true,
    pattern: '^(?:(?:No\\.|Card No\\.)\\s*)?[A-Z0-9]+(?:-[A-Z0-9]+)*$',
    patternMsg:
      'Identifier may start with "No." or "Card No." followed by alphanumeric characters, optionally separated by hyphens. e.g., "No. SMP-DJO" or "ABC-123"',
    tooltipText:
      'Usually at the top on the back of the card. Often starts with No. or Card No. E.g., No. SMP-DJO.',
  }),
  createField('Player Name(s)', 'text', {
    attachOnChange: true,
    required: true,
  }),
  createField('Year', 'text', {
    attachOnChange: true,
    required: true,
    pattern: '\\d{4}(-\\d{2})?',
    patternMsg: 'YYYY or YYYY-YY',
  }),
  createField('Manufacturer', 'text', { attachOnChange: true, required: true }),
];

export const right_detected_fields = [
  createField('Box Set', 'text', { attachOnChange: true, required: true }),
  createField('Patch', 'select', {
    attachOnChange: true,
    options: ['None', 'No specific game', 'Player Worn', 'Game Worn'],
  }),
  createField('Auto', 'radio', {
    attachOnChange: true,
    options: ['Yes', 'No'],
  }),
  createField('Number', 'text', {
    attachOnChange: true,
    pattern: '\\d+/\\d+',
    patternMsg: 'x/y, Example: 01/99',
    tooltipText: 'We only detect number if it is imprinted on the card back.',
  }),
];

export const manual_fields_with_checklist = [
  createField('Release Set', 'select', {
    tooltipText: 'We do not detect release set automatically.',
  }),
  createField('Parallel', 'select', {
    tooltipText: 'We do not detect color or parallel automatically.',
  }),
  createField('Grade', 'text', {
    tooltipText: 'We do not automatically detect the grade at this time.',
  }),
  createField('Grading Company', 'text', {
    tooltipText:
      'We do not automatically detect the grading company at this time.',
  }),
  createField('Condition', 'select', {
    options: [
      'Not specified',
      'Mint/Near Mint',
      'Excellent',
      'Very Good',
      'Poor',
    ],
  }),
];

export const manual_fields_without_checklist = [
  createField('Release Set', 'text', {
    tooltipText: 'We do not detect release set automatically.',
  }),
  createField('Parallel', 'text', {
    tooltipText: 'We do not detect color or parallel automatically.',
  }),
  createField('Grade', 'text', {
    tooltipText: 'We do not automatically detect the grade at this time.',
  }),
  createField('Grading Company', 'text', {
    tooltipText:
      'We do not automatically detect the grading company at this time.',
  }),
  createField('Condition', 'select', {
    options: [
      'Not specified',
      'Mint/Near Mint',
      'Excellent',
      'Very Good',
      'Poor',
    ],
  }),
];

export const left_column_fields = [
  createField('Card Identifier', 'text', {
    attachOnChange: true,
    required: true,
    pattern: '^(?:(?:No\\.|Card No\\.)\\s*)?[A-Z0-9]+(?:-[A-Z0-9]+)*$',
    patternMsg:
      'Identifier may start with "No." or "Card No." followed by alphanumeric characters, optionally separated by hyphens. e.g., "No. SMP-DJO" or "ABC-123"',
    tooltipText:
      'Usually at the top on the back of the card. Often starts with No. or Card No. E.g., No. SMP-DJO.',
  }),
  createField('Player Name(s)', 'text', {
    attachOnChange: true,
    required: true,
  }),
  createField('Year', 'text', {
    attachOnChange: true,
    required: true,
    pattern: '\\d{4}(-\\d{2})?',
    patternMsg: 'YYYY or YYYY-YY',
  }),
  createField('Manufacturer', 'text', { attachOnChange: true, required: true }),
  createField('Box Set', 'text', { attachOnChange: true, required: true }),
  createField('Release Set', 'text', {}),
];

export const right_column_fields_nopulse = [
  createField('Number', 'text', {
    attachOnChange: true,
    pattern: '\\d+/\\d+',
    patternMsg: 'x/y, Example: 01/99',
  }),
  createField('Patch', 'select', {
    attachOnChange: true,
    options: ['None', 'No specific game', 'Player Worn', 'Game Worn'],
  }),
  createField('Auto', 'radio', {
    attachOnChange: true,
    options: ['Yes', 'No'],
  }),
  createField('Grading Company', 'text', {}),
  createField('Grade', 'text', {}),
  createField('Parallel', 'text', {}),
  createField('Condition', 'select', {
    options: [
      'Not specified',
      'Mint/Near Mint',
      'Excellent',
      'Very Good',
      'Poor',
    ],
  }),
];

export const left_listing_fields = [
  createField('Card Identifier', 'text', {
    required: true,
    pattern: '^(?:(?:No\\.|Card No\\.)\\s*)?[A-Z0-9]+(?:-[A-Z0-9]+)*$',
    patternMsg:
      'Identifier may start with "No." or "Card No." followed by alphanumeric characters, optionally separated by hyphens. e.g., "No. SMP-DJO" or "ABC-123"',
    tooltipText:
      'Usually at the top on the back of the card. Often starts with No. or Card No. E.g., No. SMP-DJO.',
  }),
  createField('Player Name(s)', 'text', { required: true }),
  createField('Year', 'text', {
    required: true,
    pattern: '\\d{4}(-\\d{2})?',
    patternMsg: 'YYYY or YYYY-YY',
  }),
  createField('Manufacturer', 'text', { required: true }),
  createField('Box Set', 'text', { required: true }),
  createField('Release Set', 'text', {}),
];

export const right_listing_fields = [
  createField('Number', 'text', {
    pattern: '\\d+/\\d+',
    patternMsg: 'x/y, Example: 01/99',
  }),
  createField('Patch', 'select', {
    options: ['None', 'No specific game', 'Player Worn', 'Game Worn'],
  }),
  createField('Auto', 'radio', { options: ['Yes', 'No'] }),
  createField('Grading Company', 'text', {}),
  createField('Grade', 'text', {}),
  createField('Parallel', 'text', {}),
  createField('Condition', 'select', {
    required: true,
    options: ['', 'Mint/Near Mint', 'Excellent', 'Very Good', 'Poor'],
  }),
  createField('Photos', 'select', {
    required: true,
    options: [
      '',
      'Front Only',
      'Front and Corners',
      'Front and Back',
      'Front, Back and Corners',
    ],
  }),
];

const priceRegex = '^\\$?(\\d{1,3}(,\\d{3})*|\\d*)(\\.\\d{2})?$';
export const getListingFields = (eventHandlers = {}) => [
  createField('Title', 'text', { required: true, maxlength: 80 }),
  createField('Description', 'text', { required: true, rows: 5 }),
  createField('Listing Options', 'checkbox', {
    required: true,
    options: ['Auction', 'Buy It Now', 'Allow Offers'],
    eventHandlers: {
      change: eventHandlers.onListingTypeChange,
    },
  }),
  createField('Starting Bid (USD)', 'text', {
    groupName: 'Auction',
    required: true,
    pattern: priceRegex,
    patternMsg: '$X.XX',
    width: '100px',
  }),
  createField('Duration', 'select', {
    groupName: 'Auction',
    required: true,
    options: ['3 days', '5 days', '7 days', '10 days'],
  }),
  createField('BIN Price (USD)', 'text', {
    groupName: 'BuyItNow',
    required: true,
    pattern: priceRegex,
    patternMsg: '$X.XX',
    width: '100px',
  }),
  createField('Minimum Offer (USD)', 'text', {
    groupName: 'Offer',
    required: true,
    pattern: priceRegex,
    patternMsg: '$X.XX',
    width: '100px',
  }),
  createField('Auto-accept (USD)', 'text', {
    groupName: 'Offer',
    required: false,
    pattern: priceRegex,
    patternMsg: '$X.XX',
    width: '100px',
  }),
  createField('Shipping Service', 'select', {
    required: true,
    options: ['eBay Standard Envelope', 'USPS First Class', 'USPS Priority'],
  }),
  createField('Shipping Fees', 'text', {
    required: true,
    tooltipText: 'Fees the Buyer will pay. Input 0 for Free Shipping.',
    pattern: priceRegex,
    patternMsg: '$X.XX',
    width: '100px',
  }),
  createField('Handling Time', 'select', {
    required: true,
    options: [
      '1 business day',
      '2 business days',
      '3 business days',
      '4 business days',
      '5 business days',
    ],
  }),
  createField('Item Location', 'text', {
    required: true,
    tooltipText: 'We only support selling from the United States at this time.',
    readonly: true,
    defaultValue: 'United States',
    width: '200px',
  }),
  createField('Return Policy', 'select', {
    required: true,
    tooltipText: 'We only support a no return policy.',
    options: ['No returns accepted'],
  }),
];

export const reddit_post_fields = [
  createField('Photos', 'select', {
    required: true,
    options: [
      '',
      'Front Only',
      'Front and Corners',
      'Front and Back',
      'Front, Back and Corners',
    ],
  }),
  createField('Subreddit', 'select', {
    required: true,
    options: [
      '',
      'sportscards',
      'footballcards',
      'basketballcards',
      'baseballcards',
      'FootballCardz',
    ],
  }),
  createField('Title', 'text', { required: true, rows: 5 }),
  createField('Post collection link in comments', 'checkbox', {
    required: false,
  }),
];

export const parallel_select = createField('Parallel', 'select', {});
export const release_set_select = createField('Release Set', 'select', {});

export const responseToFormFieldMapping = {
  identifier: 'Card Identifier',
  manufacturer: 'Manufacturer',
  program: 'program',
  players: 'Player Name(s)',
  year: 'Year',
  autograph: 'Auto',
  auto: 'Auto',
  grade: 'Grade',
  grading_company: 'Grading Company',
  card_type: 'Sport',
  condition: 'Condition',
  // Add more mappings as needed
};

export function selectValueTransformer(value) {
  // Optionally use inputName if the transformation depends on the input
  return valueMappings[value] || value;
}

function createField(
  name,
  type,
  {
    attachOnChange = false,
    required = false,
    pattern = '',
    patternMsg = '',
    options = [],
    shouldPulse = false,
    tooltipText = '',
    rows = 1,
    groupName = '',
    width = '',
    maxlength = 0,
    readonly = false,
    defaultValue = '',
    eventHandlers = {},
  } = {}
) {
  const field = { name, type };
  const validationMessage = {};

  if (attachOnChange) {
    field.attachOnChange = true;
  }

  if (required) {
    validationMessage.valueMissing = `${name} is required.`;
    field.required = true;
  }
  if (pattern) {
    field.pattern = pattern;
    validationMessage.patternMismatch = `${name} must be input like ${patternMsg}.`;
  }

  validationMessage.default = `Unknown error in field ${name}.`;

  if (groupName) {
    field.groupName = groupName;
  }

  if (options.length) {
    field.options = options;
  }

  if (shouldPulse) {
    field.shouldPulse = true;
  }

  if (tooltipText) {
    field.tooltipText = tooltipText;
  }

  if (Object.keys(validationMessage).length) {
    field.validationMessage = validationMessage;
  }

  if (type == 'text') {
    field.rows = rows;
  }

  if (width) {
    field.width = width;
  }

  if (maxlength) {
    field.maxlength = maxlength;
  }

  if (readonly) {
    field.readonly = true;
  }

  if (defaultValue) {
    field.defaultValue = defaultValue;
  }

  // Event handlers (for radio buttons and others)
  if (Object.keys(eventHandlers).length) {
    field.eventHandlers = eventHandlers;
  }

  return field;
}
