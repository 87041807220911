import { AcceptButton, RejectButton } from '../../ui_components/buttons.js';
import { updateCardCoordinates } from '../../data/update_operations.js';
import stateManager from '../../data/state_manager.js';
import { SingleImagePanelWithCornerOverlay } from '../../ui_components/popups/two_image_panels.js';
import { fetchStreamingData } from '../../data/streaming.js';
import config from '../../../config/index.js';
import { hideOverlay, showOverlay } from '../../ui_components/overlay.js';

class FixCornersController {
  constructor(
    type,
    local_image_url,
    server_side_id,
    should_add_button2,
    should_update_text_by_default = false
  ) {
    const {
      currentFrontCoords,
      currentBackCoords,
      frontRotation,
      backRotation,
    } = stateManager.getState();
    this.type = type;
    this.should_add_button2 = should_add_button2;
    this.should_update_text_by_default = should_update_text_by_default;
    this.should_reset_request_id = false;

    if (type == 'front') {
      this.card_coordinates = currentFrontCoords;
      this.rotation = frontRotation;
    } else {
      this.card_coordinates = currentBackCoords;
      this.rotation = backRotation;
    }

    this.local_image_url = local_image_url;
    this.server_side_id = server_side_id;
    this.new_coordinates = null;

    this.closeButton = new RejectButton('Cancel');
    this.submitButton = new AcceptButton('Update Corners');
    this.submitAllButton = new AcceptButton('Update Corners and Text');
    this.view = new SingleImagePanelWithCornerOverlay(
      this.card_coordinates,
      this.rotation
    );
  }

  setTour(featureTour) {
    this.featureTour = featureTour;
  }

  createAndShowPopup(headers) {
    this.view.setImage(this.local_image_url);
    this.view.addButtonToLeft(this.closeButton, () => this.handleClose());
    this.view.addButtonToRight(this.submitButton, () =>
      this.handleSubmit(this.type, headers, this.should_update_text_by_default)
    );
    if (this.type == 'back' && this.should_add_button2) {
      this.view.addButtonToRight(this.submitAllButton, () =>
        this.handleSubmit(this.type, headers, true)
      );
    }

    this.view.bindCloseButton(() => this.handleClose());
  }

  handleClose() {
    if (this.featureTour) {
      this.featureTour.show();
    }

    this.view.remove();

    if (this.should_reset_request_id) {
      stateManager.updateState({
        stopProcessing: true,
        alertOnMismatch: false,
      });
    }
  }

  shouldResetRequestId() {
    this.should_reset_request_id = true;
  }

  async handleSubmit(type, headers, reprocess) {
    this.new_coordinates = this.view.getCorners();

    showOverlay();

    const payload = {
      parent_image_id: this.server_side_id,
      new_coordinates: this.new_coordinates,
      rotation_degrees: this.rotation,
      reprocess: reprocess,
    };

    const response = await updateCardCoordinates(headers, payload);

    console.log(response);

    const requestId = response.request_id;
    const controller = new AbortController();

    if (type == 'front' && this.should_reset_request_id) {
      stateManager.updateState({ currentFrontRequestId: requestId });
    } else if (type == 'back' && this.should_reset_request_id) {
      stateManager.updateState({ currentBackRequestId: requestId });
    }

    // abortControllers.push(controller);
    fetchStreamingData(
      `${config.detectionUrl}/get-data-stream/${requestId}`,
      headers,
      (data) => this.handleProcessingComplete(type, data),
      controller.signal
    );
  }

  async handleProcessingComplete(type, data) {
    const { currentFrontRequestId, currentBackRequestId } =
      stateManager.getState();

    if (type == 'front') {
      stateManager.updateState({ currentFrontCoords: this.new_coordinates });
      this.view.remove();
    } else {
      stateManager.updateState({ currentBackCoords: this.new_coordinates });
      this.view.remove();
    }

    if (
      stateManager.hasCard(currentFrontRequestId, currentBackRequestId) &&
      this.should_reset_request_id
    ) {
      stateManager.getNextCard(currentFrontRequestId, currentBackRequestId);
    }

    if (this.onComplete) {
      this.onComplete(data); // Call the callback function
    }

    hideOverlay();
  }

  bindCompletionCallback(callback) {
    this.onComplete = callback;
  }
  bindOnClose(handler) {
    this.view.bindOnClose(handler);
  }
}

export { FixCornersController };
