// src/utils.js

// Function to set up the user token in the request headers
export function setupUserToken(token) {
  return { Authorization: `Bearer ${token}` };
}

// Function to retrieve the user token from storage (e.g., localStorage or sessionStorage)
export function getUserToken() {
  return localStorage.getItem('userToken');
}

// Function to save the user token to storage
export function saveUserToken(token) {
  localStorage.setItem('userToken', token);
}

// Function to remove the user token from storage
export function removeUserToken() {
  localStorage.removeItem('userToken');
}
